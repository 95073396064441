<template>
  <div
    class="tab-pane fade"
    id="cargo-setting"
    role="tabpanel"
    aria-labelledby="cargo-setting-tab"
  >
    <WeCard>
      <!-- Card Title -->
      <div class="row align-items-center">
        <div class="col">
          <h5 class="mb-0">Kargo Ayarları</h5>
        </div>
        <div class="col-auto">
          <!-- Submit -->
          <div class="text-center">
            <span class="btn btn-success" v-on:click="onSave"
              ><i class="fas fa-save"></i> Kaydet</span
            >
          </div>
          <!-- ./Submit -->
        </div>
      </div>
      <!-- ./Card Title -->
      <hr />

      <!-- Cargo Tracking -->
      <!-- <WeSettingCard
        name="cargo-tracking"
        title="Kargo Takip Modülü"
        description="Kargo takip modülünü kullan"
        class="mb-2"
        v-model="data.cargo_tracking"
      /> -->
      <!-- Cargo Tracking -->

      <!-- Fixed Price -->
      <WeSettingCard
        name="fixed-price"
        title="Sabit Kargo Fiyatı"
        class="mb-2"
        v-model="data.fixed"
      />
      <!-- Fixed Price -->

      <WeInput
        v-if="data.fixed"
        label="Kargo Fiyatı"
        v-bind:prepend="'₺'"
        v-bind:filter-number="true"
        v-model="data.fixed_price"
      />

      <hr />

      <div class="row">
        <!-- Unvan -->
        <div class="col-12 col-lg-6">
          <WeInput label="Gönderici Unvanı" v-model="data.sender_title" />
        </div>
        <!-- Unvan -->

        <!-- Cargo Company -->
        <div class="col-12 col-lg-6">
          <WeInput v-model="data.cargo_company" label="Kargo Şirketi" />
        </div>
        <!-- Cargo Company -->

        <!-- Sender Phone -->
        <div class="col-12 col-lg-6">
          <WeInput
            v-model="data.cargo_sender_phone"
            label="Gönderici Telefon"
          />
        </div>
        <!-- Sender Phone -->

        <!-- Sender Email -->
        <div class="col-12 col-lg-6">
          <WeInput
            v-model="data.cargo_sender_email"
            label="Gönderici E-Posta"
          />
        </div>
        <!-- Sender Email -->

        <!-- Return Address -->
        <div class="col-12">
          <label for="return-address" class="custom-label"
            >Gönderici Kargo İade Adresi</label
          >
          <div class="row">
            <div class="col-12 col-lg">
              <div class="form-group">
                <v-select
                  label="name"
                  placeholder="Ülke"
                  v-bind:reduce="(ad) => ad.id"
                  v-bind:options="address.countries"
                  v-model="data.return_country"
                  v-bind:clearable="false"
                  v-on:input="getCityOptions"
                ></v-select>
              </div>
            </div>
            <div class="col-12 col-lg">
              <div class="form-group">
                <v-select
                  label="name"
                  placeholder="Şehir"
                  v-bind:reduce="(ad) => ad.id"
                  v-bind:options="address.cities"
                  v-model="data.return_city"
                  v-bind:clearable="false"
                  v-on:input="getDistrictOptions"
                ></v-select>
              </div>
            </div>
            <div class="col-12 col-lg">
              <div class="form-group">
                <v-select
                  label="name"
                  placeholder="İlçe"
                  v-bind:reduce="(ad) => ad.id"
                  v-bind:options="address.districts"
                  v-model="data.return_district"
                  v-bind:clearable="false"
                  v-on:input="getNeighborhoodOptions"
                ></v-select>
              </div>
            </div>
            <div class="col-12 col-lg mb-3">
              <v-select
                label="name"
                placeholder="Semt"
                v-bind:reduce="(ad) => ad.id"
                v-bind:options="address.neighborhoods"
                v-model="data.return_neighborhood"
                v-bind:clearable="false"
              ></v-select>
            </div>
            <!-- <div class="col col-lg mb-3 mb-lg-0">
              <input
                v-tooltip.top="'Posta Kodu'"
                type="text"
                class="form-control"
                placeholder="Posta Kodu"
                v-model="data.return_postal_code"
              />
            </div> -->
          </div>
          <textarea
            id="return-address"
            class="form-control"
            v-model="data.return_address"
          ></textarea>
        </div>
        <!-- Return Address -->
      </div>
    </WeCard>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "Cargo",
  data() {
    return {
      address: {
        countries: [],
        cities: [],
        districts: [],
        neighborhoods: [],
      },
      data: {
        // cargo_tracking: this.settings["cargo.tracking"] == "1" ? 1 : 0,
        fixed: this.settings["cargo.fixed"] == "1" ? 1 : 0,
        fixed_price: this.settings["cargo.fixed_price"],
        sender_title: this.settings["site.sender_title"],
        cargo_sender_phone: this.settings["site.cargo_sender_phone"],
        cargo_sender_email: this.settings["site.cargo_sender_email"],
        cargo_company: this.settings["site.cargo_company"],
        return_address: this.settings["site.return_address"],
        return_country: this.settings["site.return_country"],
        return_city: this.settings["site.return_city"],
        return_district: this.settings["site.return_district"],
        return_neighborhood: this.settings["site.return_neighborhood"],
        // return_postal_code: this.settings["site.return_postal_code"],
      },
    };
  },
  props: {
    settings: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    ...mapActions("address", [
      "getCountries",
      "getCities",
      "getDistricts",
      "getNeighborhoods",
    ]),
    onEdit() {
      this.editAddress = !this.editAddress;
      if (this.editAddress) {
        this.getAddressList();
      }
    },
    getAddressList() {
      this.address.countries = [];
      this.address.cities = [];
      this.address.districts = [];
      this.address.neighborhoods = [];

      this.getCountries({
        onSuccess: (result) => {
          this.address.countries = result.data.items;
          this.getCityOptions();
        },
      });
    },
    getCityOptions() {
      const country = this.data.return_country;
      if (country) {
        this.getCities({
          id: country,
          onSuccess: (result) => {
            this.address.cities = result.data.items;
            this.getDistrictOptions();
          },
        });
      }
    },
    getDistrictOptions() {
      const city = this.data.return_city;
      if (city) {
        this.getDistricts({
          id: city,
          onSuccess: (result) => {
            this.address.districts = result.data.items;
            this.getNeighborhoodOptions();
          },
        });
      }
    },
    getNeighborhoodOptions() {
      const district = this.data.return_district;
      if (district) {
        this.getNeighborhoods({
          id: district,
          onSuccess: (result) => {
            this.address.neighborhoods = result.data.items;
          },
        });
      }
    },
    onSave() {
      // this.settings["cargo.tracking"] = this.data.cargo_tracking;
      this.settings["cargo.fixed"] = this.data.fixed;
      this.settings["cargo.fixed_price"] = this.data.fixed
        ? this.data.fixed_price
        : "";

      this.settings["site.sender_title"] = this.data.sender_title;
      this.settings["site.mersis"] = this.data.mersis;
      this.settings["site.vkn"] = this.data.vkn;
      this.settings["site.cargo_company"] = this.data.cargo_company;
      this.settings["site.cargo_sender_phone"] = this.data.cargo_sender_phone;
      this.settings["site.cargo_sender_email"] = this.data.cargo_sender_email;
      this.settings["site.return_address"] = this.data.return_address;
      this.settings["site.return_country"] = this.data.return_country;
      this.settings["site.return_city"] = this.data.return_city;
      this.settings["site.return_district"] = this.data.return_district;
      this.settings["site.return_neighborhood"] = this.data.return_neighborhood;

      this.$parent.$emit("on-save", this.settings);
    },
  },
  mounted() {
    this.getAddressList();
  },
};
</script>
